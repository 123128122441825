import React from "react"
import { Layout } from "../components/layout"
import {ClickBox} from "../components/Home/clickbox"

const IndexPage = () => {
  return(
    <Layout title={"Austin Loza"}>
      <h1 className="text-center text-8xl md:text-9xl py-5 font-bold">Austin Loza</h1>
      <p className="text-center text-2xl md:text-3xl mb-12 md:mb-36 font-light">
        Full-Stack Developer and Machine Learning Scientist
      </p>
      <div className="flex flex-row flex-wrap justify-evenly space-y-4 md:space-y-0 py-5">
        <ClickBox
          color="red"
          link="/resume"
          first="RESU"
          second="ME"
        />

        <ClickBox 
          color = "green"
          link = "/projects"
          first="PROJ"
          second="ECTS"
        />

        <ClickBox 
          color="blue"
          link="/404"
          first="ABOUT"
          second="ME"
        />
        
      </div>
    </Layout>
  )
} 

export default IndexPage