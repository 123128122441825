import React from 'react'

export function ClickBox(props){
    function colorSelect(color){
        var classes = "flex md:w-3/12 xs:w-min w-full mx-7 h-52 justify-center cursor-pointer hover:bg-gray-300 hover:text-black flex-wrap rounded-md max-w-xs px-1"
        
        if(color === "red"){
            return classes + " bg-red-600"
        }
        else if(color === "blue"){
            return classes + " bg-blue-600"
        }
        else if(color === "green"){
            return classes + " bg-green-600"
        }

        return classes
    }

    return(
        <a href={props.link} className={colorSelect(props.color)}>
          <p className="xl:text-8xl lg:text-7xl md:text-6xl text-8xl w-full font-semibold">
            <div className="text-left">{props.first}</div>
            <div className="text-right">{props.second}</div>
          </p>
        </a>
    )
}